import { SITE_WAS_SAVED } from '../constants/events';
import { savePostPageStyle } from './post-page-style';

export const addEventListeners = async (context) => {
  await context.sdk.addEventListener(SITE_WAS_SAVED, async () => {
    try {
      await savePostPageStyle(context);
    } catch (e) {}
  });
};
